import type { PriceData } from '@/domain';
import getConfig from 'next/config';
import useFetch from 'shared/hooks/useFetch';
import useSWRMutation from 'swr/mutation';
import { createOrUpdatePriceInfo } from '@/api/api';

const {
    publicRuntimeConfig: { apiExternalUrl },
    serverRuntimeConfig: { apiInternalUrl },
} = getConfig();

export const fetchPriceDataSSR = async (adId: string): Promise<PriceData> => {
    const path = `/price/info/${adId}`;

    return await fetch(`${apiInternalUrl}/travel-api/fhh${path}`)
        .then((res) => res.json())
        .catch(() => defaultValues(adId));
};
export const priceDataUrl = (adId: string) => `${apiExternalUrl}/price/info/${adId}`;

const defaultValues = (adId: string): PriceData => ({
    adId,
    currency: null,
    defaultPriceDay: null,
    priceDayInWeekend: null,
    discountWeek: null,
    discountMonth: null,
});
export const usePriceData = (adId: string, isImmutable = false, isEnabled = true) =>
    useFetch<PriceData>({
        isEnabled,
        url: priceDataUrl(adId),
        isImmutable,
        statusCodeHandlers: {
            404: () => defaultValues(adId),
        },
    });

const post = async (_url, { arg }: { arg: PriceData }) => createOrUpdatePriceInfo(arg);

export const usePriceDataMutation = (adId: string) => useSWRMutation(priceDataUrl(adId), post, { throwOnError: false });
