import type { GetServerSidePropsContext } from 'next';
import type { RentalLocation, TempHouseRules } from '@/scenes/adInsertionDetailsPage/AdInsertionDetailsPage';
import type { CancellationPolicyId } from '@/models/cancellationPolicy';

export type ReqExtension = GetServerSidePropsContext['req'] & { query?: GetServerSidePropsContext['query'] };

export type ResExtension = GetServerSidePropsContext['res'] & { locals?: ResLocals };

export type ResLocals = {
    deviceType?: DeviceType;
    loginId?: number | null;
    spidId?: number | null;
    userToken?: string;
    authCookies?: string;
    finnToken?: string;
    unleash?: UnleashToggles;
    hasLegalBasis?: boolean;
};

export type SanitizedResLocals = Omit<ResLocals, 'finnToken' | 'userToken' | 'authCookies'>;

export type DeviceType = {
    isProbablyAndroid: boolean;
    isProbablyIos: boolean;
    isProbablyTablet: boolean;
    isProbablyMobile: boolean;
    isProbablyMobileOrTablet: boolean;
};

export interface FhhState {
    results: ResultState;
    objectData: ObjectPageData;
    profileData: ProfileData;
    orderData: OrderData & OrderResponse;
    bookingData: BookingData;
    priceData: PriceData;
}

export interface ResultState {
    data: ResultData;
    selectedAd: ResultItem | null;
    filters: Filters;
    filterData: Record<string, unknown>;
    autocompleteData: AutocompleteData;
    showFiltersOverlay?: boolean;
}

export interface ResultData {
    heatmap: Heatmap[];
    resultItems: ResultItem[] | false | null; // ffs..
    stats: ResultDataStats;
    listMap?: ResultItem[] | null; // ???
    completed?: boolean;
}

// This interface is possibly not complete. Make sure to check actual data before trusting it
export interface ResultDataStats {
    currentPage?: number;
    numResults?: number;
    pageSize?: number;
    totalPages?: number;
}

export interface ResultItem {
    id: string;
    heading: string;
    ad_type: string;
    sleeping_capacity: number;
    no_of_bedrooms: number;
    property_type: number;
    supplier_name: string;
    latitude: number;
    longitude: number;
    country?: string;
    county?: string;
    municipality?: string;
    locality?: string;
    imageurl: string;
    bookings_enabled: boolean;
    price?: number;
    price_min?: number;
    price_per_night?: number;
    upsell?: boolean;
}

export interface Heatmap {
    id: string;
    latitude: number;
    longitude: number;
    heading: string;
    imageurl: string;
    numberOfEntries: string;
}

// This data structure is coming from an external api, do not trust
export interface ProfileData {
    reputation?: { feedback: ProfileFeedback | null };
    identity?: ProfileIdentity;
    communication?: { replyTimeText: string | null };
    replyTimeText?: string | null;
    _links?: { self: { href: string } };
}

interface ProfileFeedback {
    overallScore: number;
    categoryScores: Record<string, number>;
    receivedCount: number;
}

// This data structure is coming from an external api, do not trust
export interface ProfileIdentity {
    name: string | null;
    avatar: string;
    memberSince: string | null;
    description: string | null;
    localProfileId: string;
    verified: boolean;
}

export type ItemNullableData = {
    priceInfo: PriceData | null;
    additionalCost: AdditionalCost | null;
    rentalLocation: RentalLocation | null;
    rentalHouseRules: TempHouseRules | null;
    rentalDuration: AvailabilityRules | null;
};

// This interface is not complete. Make sure to check actual data before trusting it
export interface ObjectData {
    adId: number;
    heading: string | null;
    description: string;
    residenceDescription: string;
    areaDescription: string;
    roomsDescription: string;
    situation: string | null;
    estateSize: string | null;
    propertyType: string | null;
    noOfBeds: string | null;
    noOfBedrooms: string | null;
    noOfBathrooms: string | null;
    noOfPetsMax: string | null;
    facilities: string[] | null;
    houseRules: ObjectHouseRules;
    poiNearBy: string[] | null;
    pricing: ObjectPricing;
    location: ObjectLocation;
    status: ObjectStatus;
    loginId: number;
    orgId: number | null;
    isPrivate: boolean;
    isAdmin: boolean;
    isImport: boolean;
    bookingEnabled: boolean;
    bookings: ObjectBooking[];
    user?: ObjectUser;
    owner: ObjectOwner;
    organisation?: ObjectOrganization;
    moreInfo: ObjectMoreInfo[];
    images: ObjectImage[];
    modified: string | null;
    moreAdsQuery: string | null;
}

export interface ObjectPageData {
    recommendationData?: ObjectRecommendationData;
    priceInfo?: ObjectPricing;
}

export interface ObjectHouseRules {
    smokingAllowed: boolean;
    petsAllowed: boolean;
    wheelchairAccessible: boolean;
}

export interface ObjectPricing {
    currency: string | null;
    depositAmount: number | null;
    depositType: DepositType;
    week: ObjectPriceRange | null;
    weekend: ObjectPriceRange | null;
    day: ObjectPriceRange | null;
    year: ObjectPriceRange | null;
    cleaning: number | null;
    linens: number | null;
    minimumRentalDuration: number | null;
}

export type DepositType = 'NONE' | 'REQUIRED' | 'FIXED' | 'PERCENT';

export interface ObjectPriceRange {
    from?: number | null;
    to?: number | null;
}

export interface ObjectLocation {
    address: string;
    locality: string;
    latitude: number;
    longitude: number;
    countryCode: string;
    country: string;
}

export interface ObjectStatus {
    isActive: boolean;
    isExpired: boolean;
    isDeactivated: boolean;
    isRented: boolean;
    isDeleted: boolean;
}

export interface ObjectBooking {
    bookingId: number;
    startDate: string;
    endDate: string;
}

export interface ObjectUser {
    name?: string;
    isOwner?: boolean;
}

export interface ObjectOwner {
    name: string;
    url: string | null;
    email?: string | null;
    mobile?: string | null;
    phone?: string | null;
    deeplink?: string | null;
    hasPhoneNumber: boolean;
}

export interface ObjectOrganization {
    importId: unknown | null;
    name: string | null;
    logo: string | null;
}

export interface ObjectMoreInfo {
    text: string;
    url: string;
}

export interface ObjectImage {
    height: number;
    width: number;
    hasKnownDimensions: boolean;
    thumbSrc: string;
    thumb155hSrc: string;
    description: string;
    src: string;
    srcSet: string;
}

export interface ObjectRecommendationData {
    items: ObjectRecommendationItem[];
    version: string;
}

export interface ObjectRecommendationItem {
    itemId: string;
    heading: string;
    location: ObjectRecommendationItemLocation;
    image: ObjectRecommendationItemImage;
    url: string;
    subheading: string;
    label: string;
    type: string;
    renderType: string;
    score: number;
    version: string;
}

interface ObjectRecommendationItemLocation {
    combined: string;
}

// data comes from external, not sure if values can be undefined, but code checks for it...
interface ObjectRecommendationItemImage {
    url?: string;
    type?: string;
    isAbsolute?: boolean;
}

export interface OrderData {
    adId?: number;
    step: number;
    adults: number;
    children: number;
    infants: number;
    pets: number;
    fromDate: string | null;
    toDate: string | null;
    loading: boolean;
    selectedServices: Record<string, number>;
}

export interface OrderResponse {
    available: boolean;
    message: string | null;
    bookingNumber?: number;
    bookingPrice: number | null;
    totalPrice: number | null;
    currency: string;
    currencySymbol: string;
    brand: string;
    mandatoryServices: OrderService[];
    optionalServices: OrderService[];
}

interface OrderService {
    id: number;
    type: number;
    name: string;
    feeCode: string;
    description: string | null;
    price: number;
    priceMultiplier: number;
    currency: string;
    currencySymbol: string;
    amountType: string;
    max: number;
}

export type ApiActionStatus = 'NOT_STARTED' | 'PENDING' | 'ERROR' | 'OK';

export interface Booking {
    bookingId: number;
    ownerFinnUserId: number | null;
    renterFinnUserId: string | null;
    rentalId: number;
    startDate: string;
    endDate: string;
    name: string;
    phoneNumber: string | null;
    email: string | null;
    bookingNotes: string | null;
    bookingReference: string | null;
    image: string;
}

export type SelectedBooking = Partial<Booking> & { loginId?: string; prefilled?: boolean };

export interface BookingData {
    adId: string | null;
    enabled: boolean;
    rentalEnabled: boolean; // TODO REIS-5377 remove
    bookings: Booking[];
    outdated: Booking[]; // TODO REIS-5377 remove
    selected: SelectedBooking | null;
    bookingStatus: ApiActionStatus;
    newEmptyBooking: boolean;
}

export interface ConversationPartner {
    loginId: string;
    name: string;
    image: string;
}

export interface PriceData {
    adId: string | null;
    currency: string | null;
    defaultPriceDay: number | null;
    priceDayInWeekend: number | null;
    discountWeek: number | null;
    discountMonth: number | null;
}

export interface PriceSummary {
    priceInfo: PriceData;
    priceRanges: PriceRanges;
    additionalCost: AdditionalCost;
    minimumRentalDuration: number | null;
}

export interface PriceRanges {
    week: ObjectPriceRange | null;
    weekend: ObjectPriceRange | null;
    day: ObjectPriceRange | null;
}

export interface PriceCalendar {
    adId: string;
    pricesOfDateRange: number[];
    priceOfStayWithExtraCost: number;
    discount: number;
    cleaningType: CleaningType;
    cleaningFee: number;
    priceNotIncludingServiceFee: number;
    serviceFee: number;
    priceIncludingServiceFee: number;
    currency: string;
    deposit: boolean;
}

type CleaningType = 'INCLUDED' | 'FEE' | 'GUEST_CLEANS';

export interface PriceOverride {
    adId: string;
    priceDay: number;
    startDate: string;
    endDate: string;
}

export interface AdditionalCost {
    adId: string | null;
    priceDepositType: PriceDeposit;
    priceDepositAmount: number | null;
    priceDepositRequired: boolean | null;
    priceCleaningType: PriceCleaningType;
    priceCleaning: number | null;
    priceLinens: number | null;
    priceExtraPerGuest: number | null;
    priceExtraPerGuestWhenCountAbove: number | null;
}

export type PriceDeposit = 'FIXED' | 'PERCENT' | 'REQUIRED' | 'NONE' | null;

export type PriceCleaningType = 'INCLUDED' | 'FEE' | 'GUEST_CLEANS';

export type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
export type NorwegianDay = 'Mandag' | 'Tirsdag' | 'Onsdag' | 'Torsdag' | 'Fredag' | 'Lørdag' | 'Søndag';
export type Order = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type CheckinDayInput = {
    label: NorwegianDay;
    value: Day;
};

export type CheckinDayWithOrder = {
    day: Day;
    dayInNorwegian: NorwegianDay;
    checkinDay: boolean;
    order: number;
};

export type CheckinDay = {
    day: Day;
    checkinDay: boolean;
};

export interface AvailabilityRules {
    adId: number | null;
    minRentalDuration: number;
    maxRentalDuration: number | null;
    availabilityList: CheckinDayWithOrder[] | CheckinDay[] | null;
    advanceBookingLimit: number | null;
    cancellationPolicy?: CancellationPolicyId | null;
}

export interface AvailabilityPerDate {
    itemId: number;
    date: string;
    available: boolean;
    checkin: boolean;
    checkout: boolean;
    minDuration: number;
    maxDuration: number | null;
}

export type Filters = {
    lat_sw?: string;
    lng_sw?: string;
    lat_ne?: string;
    lng_ne?: string;
    nrFUSAds?: number;
    booking_from?: string | null;
    booking_to?: string | null;
    page?: number | null;
    sorting?: string | null;
    no_of_bedrooms_from?: number;
    no_of_beds_from?: number;
    is_transactional?: boolean;
    price_from?: number;
    price_to?: number;
    property_types?: string[];
    facilities?: string[];
    pois_nearby?: string[];
    is_private?: string | boolean;
    destination?: string | null; //is this ever used? does it ever have a value? assuming strings for now
    country?: string | null; //same as destination
    city?: string | null; //this is used, but does it ever have a value?
};

export interface AutocompleteData {
    suggestions: AutocompleteSuggestion[];
    showSuggestions: boolean;
    selectedValue?: string | null;
    trackingInfo?: AutocompleteTrackingInfo;
}

export interface AutocompleteSuggestion {
    type: string;
    primary: string;
    secondary: string;
    numResults: number;
    query?: string;
}

export interface AutocompleteTrackingInfo {
    city: string;
    country: string;
    numResults: number;
}

export interface UnleashToggles {
    novasolDirectBooking: boolean;
    interhomeDirectBooking: boolean;
    sologstrandDirectBooking: boolean;
    dancenterDirectBooking: boolean;
    belvillaDirectBooking: boolean;
    danlandDirectBooking: boolean;
    tuiDirectBooking: boolean;
    yourrentalsDirectBooking: boolean;
    norefjellhyttaDirectBooking: boolean;
    cabinlivingDirectBooking: boolean;
    hotjar: boolean; // Is only used for legal basis
    hotjarResultList: boolean;
    newBookingCalendar: boolean;
    discountRentalRequest: boolean;
    newSearch: boolean;
    tjvrCancelReasonFeedback: boolean;
    mvvProductPage: boolean;
}

export type SwrFallback = { [key: string]: unknown }; // This should be defined with the actual proper swr object...

export enum EventTypes {
    BOOKING = 'booking',
    BOOKING_BLOCKED = 'bookingBlocked',
    BOOKING_SELECT = 'bookingSelect',
    INVALID_CHECKIN_DAY = 'invalidCheckinDay',
    MINIMUM_RENTAL_DURATION = 'minimumRentalDuration',
    SELECT = 'select',
    EXPIRED = 'expired',
}
